// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"

// Social Icon
import githubIcon from "./images/github.svg"
import codepenIcon from "./images/codepen.svg"
import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "SnailDOS Discover",
  headerTagline: [
    //Line 1 For Header
    "Building future",
    //Line 2 For Header
    "powering brands,",
    //Line 3 For Header
    "evolving experience",
  ],
  //   Header Paragraph
  headerParagraph:
    "SnailDOS isn't a tech service, we are a team, a team of talented users that create the future. Rule one, clients evolve? SnailDOS evolves along side. SnailDOS is privacy based, meaning your data, is, always and will, be encrypted with top encryption possible. What do we do? Scroll down...",

  //Contact Email
  contactEmail: "snaildos@snaildos.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "SnailFM",
      para:
        "SnailFM is a free online based radio station that streames noncopyright music, that artists gave legal permission for us to use. SnailFM has it's own Mobile App and a Desktop Application.", // Add Your Service Type Here
      imageSrc:"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8NDw8NDQ0QDQ0NEBINDQ0NEA8NDQ0NFRIWFhURFRUYHSggGBolGxUVITEhMSkrLi46Fx8zODMtNygtOisBCgoKDQ0NFQ0NFTcZFRkrKy03NysrKy03LS03KystLSstNystNystLSsrKysrLSsrNy0rKysrKystLTcrKysrLf/AABEIAKgBLAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAHhABAAMAAwADAQAAAAAAAAAAADHh8AFB0REhUaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AOIgio0gAoAKIaQUoK7BRNJpEUTSukC1ZvtdICppNIKJpPf0ClSuzSBx4Jx52aQUtNJfYBZyl9iqippABAVAABEA5AFBFFABQ0gKgaQUo0ldgKmk0iKJzvtdIFiX2ukDQqaTSAqaTSBQV2aQOPBOPOzSCpZpL7FCzSl9gqLpTSACAqAACIAHIKIAoCiggKAClIoAACoAWqWukQ0GhNKgaD1N/VA4E48AOFTjfYKFhYBYlgAAIqAAAIqIByHIAAKAAAopoEBdBXQceAug0IoGg0ABfS/O+EsA0LoQENC6EBT53waAA0GgANCX0ALoTQFgAgKioAAgIAByICgAogooAKgAoAAAKIAX+KlqAIoAigCKCcKgAAAAAioAAAioAAgIqAoAACigiCgKKgAogCiKBYl9rpAVABU0gAaTSAJpXSAIAqAACIKiooAIAIAAKAAoAgACiAKIKKACiAKIAoigCAKIoAgAAACAqKiCoAAACAKAgKACiAigAAAogCiKoAACKAIqAAoCKAIqAIAogCoAAACAACCqgAogCiKAqAKICKAAAAqACoAogCiAKIAKgAAAAACAqAKAAIAAAAACoAogCgAAAogIoigCKAAAAAAAIAogAAKqCAogCoAAAAAAAAAAAKAAAAAIKAqKAIoCAACKAgAoAAAAgAAAAAAAIAP/2Q==",
      url: "https://snaildos.com/snailfm",
    },
    {
      title: "StreamBop", //Project Title - Add Your Project Title Here
      para:
        "StreamBop is based on the SnailFM radio station, it's a new software that automatically credits song names, with custom UI to add to your stream, mixer and auto updates. Check our website for more.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1605153864431-a2795a1b2f95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTY3ODl8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://streambop.snaildos.com",
    },
    {
      title: "Fifo Browser", //Project Title - Add Your Project Title Here
      para:
        "Fifo Browser is a browser based on modern technologies. It's based on the chromium rendering engine, meaning all your favourite sites will work just like they used to! With privacy in key, Fifo doesn't track any data, the only time it does something without you giving it permission is to update it's self. Fifo is a modern based browser with UI sleek, a easy setup and migration for people who just started and more...", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://media.discordapp.net/attachments/861802593474838559/933002415786057728/fifo_but_black.png",
      //Project URL - Add Your Project Url Here
      url: "https://fifo.snaildos.com",
    },
    {
      title: "SnailCloud", //Project Title - Add Your Project Title Here
      para:
        "SnailCloud is a privacy based cloud storage. You get 5GB free storage with encrypted file uploads. SnailCloud has the best applications that users can use inside the app to power there day, with Todolists, Passwords managers and more.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images-ext-2.discordapp.net/external/HfmWHhROEIAhifEAK5OkVscMHE7tnIUFh_0lNheadlU/https/img.snaildos.com/u/PvEW3Z.png",
      //Project URL - Add Your Project Url Here
      url: "https://cloud.snaildos.com",
    },
    {
      title: "Creatable Host", //Project Title - Add Your Project Title Here
      para:
        "Creatable Host, is the most secure and fast hosting services for discord bots, Lavalink, Vscode in browser, HasteBin servers and more. The best cheap, and affordable hosting ever seen yet, and we have been running for 2 years...", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://media.istockphoto.com/photos/extreme-closeup-of-supercomputer-picture-id1073009100?b=1&k=20&m=1073009100&s=170667a&w=0&h=Gp1rf7lPPxNqLZUGjMmUwTL5QRaaMS90r1SJRiIOIMs=",
      //Project URL - Add Your Project Url Here
      url: "https://create.snaildos.com",
    },
    {
      title: "Message Backup Bot", //Project Title - Add Your Project Title Here
      para:
        "Message Backup Bot is a discord bot that allows backing up and restoring messages. This can be helpful with raides, copying rules, archiving and more. Of course, once again, It's security based meaning we collect MINIMAL data and all data including messages is heavily encrypted.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://media.discordapp.net/attachments/838596564040810516/955460660844691546/texthand_NEWsite.jpg?width=1377&height=775",
      //Project URL - Add Your Project Url Here
      url: "https://messagebackup.snaildos.com",
    },
    {
      title: 'SnailPortal',
      para: 'SnailPortal, AKA, Bubble ID, is a single sign on service provided by SnailDOS, This portal website allows managment of all SnailDOS services and more.',
      imageSrc: "https://cdn.snaildos.com/logo/Snail_Portal.png",
      url: 'https://portal.snaildos.com'
  },
  {
    title: 'SnailDOS App',
    para: 'SnailDOS App allows intergration of all SnailDOS services on mobile, and PC. Easily download the app, manage your servers easily, and simply with the app.',
    imageSrc: "https://images-ext-2.discordapp.net/external/uO0CSRISwwRZ8_lavc61jdMlcFI3xCRjIZzmvIykVRs/https/img.snaildos.com/u/2TM4qY.png?width=642&height=640",
    url: 'https://portal.snaildos.com'
  }

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "SnailDOS is located in Australia with a team of staff around the world. We're heavily privacy orientated and do tons of different services. .",
  aboutParaTwo:
    "Our services don't EOL unless there is a 6 month notice, most of our services have been running for more then one year without trouble. We're powering thousands of individuals every single day, who knows, maybe you will be next?.",
  aboutParaThree:
    "We're powering imagination, but with privacy. We're using military grade encryption with lots more comming..",
  aboutImage:
    "https://snaildos.com/assets/img/hero-img.png",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "What's next?",
  promotionPara:
    "We don't know! We're evolving so fast and taking most feedback from our users into consideration, we're working hard to make sure you get the best experience possible. So, who knows!",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/snaildos" }
  ]

  // End Contact Section ---------------
}

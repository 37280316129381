import React from "react"
import data from "../yourdata"

const Skills = () => {
  return (
    <div className="section">
    </div>
  )
}

export default Skills
